import { useMemo } from 'react';
import { getIsExistingSr } from 'common/utils/srUtils';
import { getFieldValue } from 'common/utils/fieldUtils';
import { IntegrationTypes } from './IntegrationSection.consts';
import { JIRA_ACTION_FIELD_NAME, JIRA_ISSUEKEY_FIELD_NAME } from './components/jira/jira.consts';
import { JiraIssueDetails } from './components/jira/JiraIssueDetails';
import JiraIntegrationForm from './components/jira/JiraIntegrationForm';

const IntegrationSection = (props) => {
  const { type, sr, fields, onFieldChange, handleFieldFocus, onGetJiraIssueKey } = props;
  const isExistingSR = getIsExistingSr(sr?.id);

  const actionField = fields.find(field => field.fieldName === JIRA_ACTION_FIELD_NAME);
  const issueKeyField = fields.find(field => field.fieldName === JIRA_ISSUEKEY_FIELD_NAME);
  const actionFieldValue = useMemo(() => getFieldValue(actionField, sr), [actionField, sr]);
  const issueKeyFieldValue = useMemo(() => getFieldValue(issueKeyField, sr), [issueKeyField, sr]);

  const isJiraLinked = Boolean(issueKeyFieldValue);
  const showWidget = isJiraLinked && isExistingSR;

  const showForm = !isExistingSR || (isExistingSR && !isJiraLinked);

  switch (type) {
    case IntegrationTypes.JIRA:
      return (
        <>
          {showForm &&
            <JiraIntegrationForm
              isJiraLinked={isJiraLinked}
              isExistingSR={isExistingSR}
              sr={sr}
              actionField={actionField}
              parentFieldValue={actionFieldValue}
              issueKeyFieldName={issueKeyField?.fieldName}
              onParentFieldChange={onFieldChange}
              handleFieldFocus={handleFieldFocus}
              onGetJiraIssueKey={onGetJiraIssueKey}
              />
          }
          {showWidget &&
            <JiraIssueDetails
              issueKeyField={issueKeyField}
              actionField={actionField}
              jiraIssueKey={issueKeyFieldValue}
              sr={sr}
            />
          }
        </>
      );
    default:
      break;
  }
};

export default IntegrationSection;
