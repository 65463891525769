import { styled } from '@mui/material/styles';
import { Box, CardActions, Menu, Stack, TextField } from '@mui/material';

export const StyledFormFooter = styled(CardActions)(() => ({
  justifyContent: 'flex-end',
  gap: '12px',
  padding: '0',
}));

export const StyledWidgetContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '380px',
  fontSize: theme.fontSize.main,
  backgroundColor: theme.palette.grey.bt_alt_grey_light,
  padding: '11px 10px',
  columnGap: '10px',
  '.widgetContentWrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
    rowGap: '8px',
    columnGap: '24px',
  },
  '.widgetContentBlock': {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '24px',
  },
  '.widgetLoading': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '24px',
  },
}));

export const StyledLoading = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const FieldGroupWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  borderRadius: '8px',
  padding: '20px',
  marginLeft: '-8px',
  marginTop: '12px',
  width: '538px',
}));

export const FieldsRowWrapper = styled(Stack)({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '12px',
  width: '49%',
  position: 'static',
  borderRadius: '4px',
  padding: '8px 4px',
  ':first-child, :nth-child(3)': {
    paddingRight: '5%',
  },
  ':last-child, :nth-child(2)': {
    paddingLeft: '5%',
  },
});

export const StyledDropdown = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  maxWidth: 'calc(100% - 200px)',

  '.field-name-title': {
    padding: '2px 0px 2px 8px',
  },
});

export const handleBackgroundColor = ({ isOpen, hasValue, isEnabled, theme }) => {
  if (!isEnabled) return 'inherit';
  if (hasValue) return theme.palette.generic.primary;
  return isOpen
    ? theme.palette.background.bg_hover_main
    : theme.palette.background.bg_new_input_text;
};

export const handleBorderColor = ({ isEnabled, hasValue, theme }) => {
  if (!isEnabled || hasValue) return theme.palette.grey.divider_grey_v1;
  return theme.palette.blueLabels.bt_blue_field_empty_hover;
};

export const StyledInputContainer = styled('div', {
  shouldForwardProp: (prop) => !['isEnabled', 'isOpen', 'hasValue'].includes(prop),
})(({ theme, isEnabled, isOpen, hasValue }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  backgroundColor: handleBackgroundColor({ isEnabled, isOpen, hasValue, theme }),
  border: `1px solid ${handleBorderColor({ isEnabled, hasValue, theme })}`,
  borderRadius: '4px',
  
  '& > svg': {
    position: 'absolute',
    right: '8px',
    pointerEvents: 'none',
  },

  '& .MuiOutlinedInput-root': {
    cursor: isEnabled ? 'pointer' : 'default',
  },
}));

export const StyledIntegrationFieldContainer =  styled(Box)({
  flexBasis: '49%',
  alignItems: 'center',
  position: 'relative',
  display: 'flex',
  paddingLeft: '16px',

  '.MuiBox-root': {
    maxWidth: '100%',
  },
});

export const StyledIntegrationTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['isEnabled', 'isOpen', 'hasValue'].includes(prop),
})(({ theme, isEnabled }) => ({
  width: '100%',
  cursor: isEnabled ? 'pointer' : 'default',
  
  '& .MuiOutlinedInput-root': {
    paddingRight: '24px',
    
    '& .MuiOutlinedInput-input': {
      cursor: 'inherit',
      fontFamily: theme?.fonts?.robotoRegular,
      width: '116px',
      fontSize: '.875rem',
      height: 'unset',
      lineHeight: 1.4286,
      minHeight: 'unset',
      padding: theme?.spacing(0.5, 1),
      paddingRight: 0,
      color: theme.palette.text.txt_default,
      '&::placeholder': {
        opacity: 1,
        color: isEnabled ? theme.palette.blueLabels.bt_blue_chip_reg : theme.palette.grey.chip_grey,
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '&.MuiInputBase-fullWidth': {
      '& .MuiOutlinedInput-input': {
        width: '100%',
      },
    },
  },
  '& .MuiFormHelperText-root': {
    margin: 0,
  },
}));

export const StyledDropDownMenu = styled(Menu, {
  shouldForwardProp: (prop) => !['containerWidth'].includes(prop),
})(({ theme, containerWidth }) => ({
  '.MuiPaper-root': {
    padding: '8px',
    borderRadius: '6px',
    maxHeight: '250px',
    boxShadow: `0px 2px 8px ${theme.palette.background.boxShadow}`,
    height: 'fit-content',
    width: containerWidth,
  },
  ul: {
    padding: 0,
    height: '100%',
  },
  li: {
    padding: '0',
    '>*': {
      padding: '12px 16px',
      width: '100%',
      justifyContent: 'flex-start',
    },
    '&:hover': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      padding: '0',
      alignSelf: 'stretch',
      borderRadius: '6px',
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
  },
}));
