import { useMemo } from "react";
import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = useMemo(() => ({
    addJiraSection: texts?.['spaces.template.sections.integrations.addJiraSection'] || 'Add Jira Integration',
    jiraSectionTitle: texts?.['spaces.template.sections.jiraSectionTitle'] || 'Jira Integration',
    unlinkJiraIssue: texts?.['spaces.integrations.jira.actions.unlink'] || 'Unlink Jira Issue',

    searchPlaceholder: texts?.['spaces.integrations.fields.search'] || 'Search',
    clear: texts?.['spaces.integrations.fields.clear'] || 'Clear',
    placeholder: texts?.['spaces.integrations.fields.select'] || 'Select',
    emptyResults: texts?.['spaces.integrations.fields.noResults'] || 'No results',
    notAvailable: texts?.['spaces.integrations.fields.notAvailable'] || 'N/A',
    unlinkBtn: texts?.['spaces.integrations.jira.prompt.unlink'] || 'Unlink',
    cancelBtn: texts?.['spaces.integrations.jira.prompt.cancel'] || 'Cancel',
    okBtn: texts?.['spaces.integrations.jira.prompt.ok'] || 'OK',

    FIELDS: {
      jiraProjectField: texts?.['spaces.integrations.jira.fields.project'] || 'Jira Project',
      jiraTypeField: texts?.['spaces.integrations.jira.fields.type'] || 'Jira Type',
      jiraParentIssueField: texts?.['spaces.integrations.jira.fields.parentIssue'] || 'Jira Parent Issue',
      jiraAssigneeField: texts?.['spaces.integrations.jira.fields.assignee'] || 'Jira Assignee',
      jiraIssueField: texts?.['spaces.integrations.jira.fields.issue'] || 'Jira Issue',
    },

    JIRA_ERRORS: {
      errorFetchingJiraData: {
        title: texts?.['spaces.integrations.jira.errors.errorFetchingData.title'] || 'Unable to contact Jira',
        message: texts?.['spaces.integrations.jira.errors.errorFetchingData.message'] || 'Refresh and try again. If the issue persists, reach out to the system administrator.',
      },
      errorUnlinkIssue: {
        title: texts?.['spaces.integrations.jira.errors.errorUnlinkIssue.title'] || 'Unable to Unlink Jira Issue',
        message: texts?.['spaces.integrations.jira.errors.errorUnlinkIssue.message'] || 'An error occurred while unlinking Jira issue. Please try again later.',
      },
      fieldsInvalidError: {
        title: texts?.['spaces.integrations.jira.errors.fieldsInvalid.title'] || 'Required fields missing',
        message: texts?.['spaces.integrations.jira.errors.fieldsInvalid.message'] || 'One or more Required field is missing',
      },
      createJiraIssueFailure: {
        title: texts?.['spaces.integrations.jira.errors.createJiraIssueFailure.title'] || 'Unable to create Jira issue',
      },
      errorFetchingJiraIssue: {
        message: texts?.['spaces.integrations.jira.errors.errorFetchingJiraIssue.message'] || 'Error fetching data from Jira for issue key: ',
      },
    },
    
    loadingJiraIssueText: texts?.['spaces.integrations.jira.loading.loadingJiraIssueText'] || 'Fetching data...',
    stillLoadingJiraIssueText: texts?.['spaces.integrations.jira.loading.stillLoadingJiraIssueText'] || 'Still fetching data...',
    unlinkIssueMessage: texts?.['spaces.integrations.jira.prompt.unlinkWarning'] || 'This action will unlink the Jira issue from the SR. Are you sure you want to proceed?',
  }), [texts]);

  return componentTexts;
};
