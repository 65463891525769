import { useCallback, useRef } from 'react';
import { useDebounceCallback } from 'common/utils/hooks/useDebounceCallback';
import { isEmpty } from 'common/utils/utils';
import { SEARCH_DEBOUNCE_TIME, MIN_CHARS_FOR_API_SEARCH, JIRA_API_PAGE_SIZE } from '../components/jira/jira.consts';

export const useIntegrationSearch = (fieldName, options, onSearch) => {
  const isApiSearchMode = useRef(false);
  const minCharsForApiSearch = MIN_CHARS_FOR_API_SEARCH[fieldName] || 1;

  const handleSearch = useCallback((value) => {
    const hasEnoughChars = value.length >= minCharsForApiSearch;
    const currentLength = options?.length ?? 0;
    const shouldUseApiSearch = hasEnoughChars && (currentLength === 0 || currentLength === JIRA_API_PAGE_SIZE);
    isApiSearchMode.current = shouldUseApiSearch;
    onSearch(shouldUseApiSearch ? value : undefined);
  }, [minCharsForApiSearch, options, onSearch]);

  const debouncedSearch = useDebounceCallback(handleSearch, SEARCH_DEBOUNCE_TIME);

  const onSearchChange = useCallback((e) => {
    const newSearchString = e.target.value;
    
    if (isEmpty(newSearchString)) {
      isApiSearchMode.current = false;
      onSearch();
      debouncedSearch.cancel();
    } else {
      debouncedSearch(newSearchString);
    }
    
    return newSearchString;
  }, [debouncedSearch, onSearch]);

  const onSearchClear = useCallback(() => {
    isApiSearchMode.current = false;
    onSearch();
  }, [onSearch]);

  return {
    isApiSearchMode,
    onSearchChange,
    onSearchClear,
    debouncedSearch
  };
}; 