import { useQuery } from '@tanstack/react-query';
import { QUERIES_KEYS, QUERY_STALE_TIME } from 'constant';
import { getProjects, getIssuesByProject, getUsers, getIssueDetails } from 'services/jiraIntegration';

export function useJiraProjects(filter) {
  const safeFilter = filter?.trim();
  return useQuery({
    queryKey: [QUERIES_KEYS.JIRA_PROJECTS, safeFilter],
    queryFn: () => getProjects(safeFilter),
    select: (data) => data?.map(projectData => ({
      key: projectData.key,
      label: projectData.name,
      issueTypes: projectData.issueTypes,
    })),
    staleTime: QUERY_STALE_TIME.MEDIUM,
    placeholderData: [],
  });
}

export function useJiraIssues(projectKey, filter) {
  const safeFilter = filter?.trim();
  return useQuery({
    queryKey: [QUERIES_KEYS.JIRA_ISSUES, projectKey, safeFilter],
    queryFn: () => getIssuesByProject(projectKey, safeFilter),
    select: (data) => data?.map(issueData => ({
      key: issueData.key,
      label: issueData.name,
    })),
    staleTime: QUERY_STALE_TIME.MEDIUM,
    placeholderData: [],
    enabled: !!projectKey,
  });
}

export function useJiraAssignees(projectKey, filter) {
  const safeFilter = filter?.trim();
  return useQuery({
    queryKey: [QUERIES_KEYS.JIRA_ASSIGNEES, projectKey, safeFilter],
    queryFn: () => getUsers(projectKey, safeFilter),
    select: (data) => data?.map(userData => ({
      key: userData.accountId,
      label: userData.displayName,
    })),
    staleTime: QUERY_STALE_TIME.MEDIUM,
    placeholderData: [],
    enabled: !!projectKey,
  });
}

export function useJiraIssueDetails(issueKey) {
  return useQuery({
    queryKey: [QUERIES_KEYS.JIRA_ISSUE_DETAILS, issueKey],
    queryFn: () => getIssueDetails(issueKey),
    staleTime: QUERY_STALE_TIME.SHORT,
    refetchInterval: QUERY_STALE_TIME.DEFAULT,
    refetchOnWindowFocus: true,
    enabled: !!issueKey,
  });
}
