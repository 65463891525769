import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { DATE_FORMATS } from 'common/constants/dateFormats';
import isEmpty from 'lodash/isEmpty';
import JiraLogo from 'images/photos/jira-logo.png';
import { unlink } from 'services/jiraIntegration';
import { useUpdateSR } from 'remote-state/ticketServiceHooks';
import { lockSR } from 'services/ticketService';
import { selectActiveUser } from 'store/userSlice';
import { KebabMenu } from 'common/components/kebabMenu';
import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as ErrorSign } from 'images/icons/ErrorSign.svg';
import { ReactComponent as WarningSign } from 'images/icons/WarningSignNew.svg';
import CircularProgress from 'common/components/circularProgress';
import { StyledWidgetContainer, StyledLoading } from '../../integrations.styles';
import {
  TIMEOUT_30_SECONDS,
  UNLINK_JIRA_ISSUE,
} from './jira.consts';
import useTexts from '../../useTexts';
import { useJiraIssueDetails } from './useJiraData';

export const JiraIssueDetails = (props) => {
  const { issueKeyField, jiraIssueKey, actionField, sr } = props;
  const {
    JIRA_ERRORS,
    unlinkJiraIssue,
    cancelBtn,
    unlinkBtn,
    okBtn,
    unlinkIssueMessage,
    loadingJiraIssueText,
    stillLoadingJiraIssueText
  } = useTexts();
  const { checkAndUpdateSr } = useUpdateSR();
  const userAccount = useSelector(selectActiveUser);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(loadingJiraIssueText);
  const [linkedIssue, setLinkedIssue] = useState();
  const [jiraErrorMessage, setJiraErrorMessage] = useState(null);
  const [prompt, setPrompt] = useState(null);

  const { data: issue, error } = useJiraIssueDetails(jiraIssueKey);

  useEffect(() => {
    if (issue) {
      setLinkedIssue(issue);
      setIsLoading(false);
    }
    if (error) {
      setJiraErrorMessage(`${JIRA_ERRORS.errorFetchingJiraIssue.message} ${jiraIssueKey}`);
      setIsLoading(false);
      // TODO proper error handling
      console.log('Error fetching issue data. IssueKey: ', jiraIssueKey, error);
    }
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        setLoadingMessage(stillLoadingJiraIssueText);
      }
    }, TIMEOUT_30_SECONDS);
    return () => clearTimeout(timeoutId);
  }, [JIRA_ERRORS.errorFetchingJiraIssue.message, stillLoadingJiraIssueText, jiraIssueKey, isLoading, issue, error]);

  const { key, name, status, lastUpdateDate, url } = linkedIssue ?? {};
  const formattedUpdateDate = lastUpdateDate ? format(parseISO(lastUpdateDate), DATE_FORMATS.SHORT_DATE) : null;

  const unlinkIssue = async () => {
    // TODO add loader?
    try {
      await unlink({ srId: sr.id.toString() });
      setLinkedIssue(null);
      lockSR({ username: userAccount?.username, srId: sr.id })
        .then(() => checkAndUpdateSr({
          requestParams: {
            [issueKeyField.fieldName]: null,
            [actionField.fieldName]: null,
          },
          id: sr.id,
          queueUpdate: true,
        }));
    } catch (error) {
      setPrompt({
        title: JIRA_ERRORS.errorUnlinkIssue.title,
        message: JIRA_ERRORS.errorUnlinkIssue.message,
        btnOkText: okBtn,
        onOkClick: () => setPrompt(null),
        onCancelClick: () => setPrompt(null),
        icon: ErrorSign,
      });
      // TODO proper error handling
      console.log('Error unlinking issue:', error);
    }
  };

  const handleUnlinkClick = () => {
    setPrompt({
      title: unlinkJiraIssue,
      message: unlinkIssueMessage,
      btnOkText: unlinkBtn,
      btnCancelText: cancelBtn,
      onOkClick: unlinkIssue,
      onCancelClick: () => setPrompt(null),
      icon: WarningSign,
    });
  };

  const handleKebabMenuAction = (actionName) => {
    switch (actionName) {
      case UNLINK_JIRA_ISSUE:
        handleUnlinkClick();
        break;
      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <StyledWidgetContainer data-testid="jira-issue-details-container-loading">
        <div className="widgetContentWrapper">
          <img className="toggle-img" src={JiraLogo} alt="Jira Icon" />
          <div className="widgetLoading">
            <div>{loadingMessage}</div>
            <StyledLoading>
              <CircularProgress size={18} />
            </StyledLoading>
          </div>
        </div>
        <KebabMenu
          className="widgetContentMenu"
          alignToLeft={false}
          isVisible
          handleKebabMenuAction={handleKebabMenuAction}
          actionList={[{ name: UNLINK_JIRA_ISSUE, defaultCaption: unlinkJiraIssue }]}
        />
      </StyledWidgetContainer>
    );
  }

  if (jiraErrorMessage) {
    return (
      <StyledWidgetContainer data-testid="jira-issue-details-container-error">
        <div className="widgetContentWrapper">
          <img className="toggle-img" src={JiraLogo} alt="Jira Icon" />
          <div className="widgetContentBlock">{jiraErrorMessage}</div>
        </div>
        <KebabMenu
          className="widgetContentMenu"
          alignToLeft={false}
          isVisible
          handleKebabMenuAction={handleKebabMenuAction}
          actionList={[{ name: UNLINK_JIRA_ISSUE, defaultCaption: unlinkJiraIssue }]}
        />
      </StyledWidgetContainer>
    );
  }

  return (
    <>
      <StyledWidgetContainer data-testid="jira-issue-details-container">
        <img className="toggle-img" src={JiraLogo} alt="Jira Icon" />
        <div className="widgetContentWrapper">
          <div className="widgetContentBlock firstBlock">
            <b>{name}</b>
          </div>
          <div className="widgetContentBlock">
            <a href={url} target="_blank" rel="noreferrer">
              {key}
            </a>
            <div>{status}</div>
            { formattedUpdateDate ? <div>Updated: {formattedUpdateDate}</div> : <></> }
          </div>
        </div>
        <KebabMenu
          className="widgetContentMenu"
          alignToLeft={false}
          isVisible
          handleKebabMenuAction={handleKebabMenuAction}
          actionList={[{ name: UNLINK_JIRA_ISSUE, defaultCaption: unlinkJiraIssue }]}
        />
      </StyledWidgetContainer>

      {prompt ? (
        <MessagePrompt
          open
          onClose={prompt.onCancelClick}
          onOkClick={prompt.onOkClick}
          showCancelBtn={!isEmpty(prompt.btnCancelText)}
          title={prompt.title}
          btnOkText={prompt.btnOkText}
          btnCancelText={prompt.btnCancelText}
          Icon={prompt.icon}
        >
          {prompt.message}
        </MessagePrompt>
      ) : (
        <></>
      )}
    </>
  );
};
