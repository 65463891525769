import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { isEmpty } from 'common/utils/utils';
import TemplateFieldsGrid from 'features/srPanel/templateFieldsGrid';
import useTexts from 'features/resolutionPanel/useTexts';
import useJiraTexts from 'features/IntegrationSection/useTexts';
import { createIssue } from 'services/jiraIntegration';
import { useTicketLockStatus, useUpdateSR } from 'remote-state/ticketServiceHooks';
import { lockSR } from 'services/ticketService';
import { selectActiveUser } from 'store/userSlice';
import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as ErrorSign } from 'images/icons/ErrorSign.svg';
import {
  useJiraFields,
  jiraActionFieldValues,
  initialFieldValues,
  initialFieldOptions,
  initialFieldSearchFilters,
  JIRA_ERROR_TYPES,
} from './jira.consts';
import { IntegrationFieldComponent } from '../IntegrationFieldComponent';
import { StyledFormFooter, FieldGroupWrapper, FieldsRowWrapper, StyledIntegrationFieldContainer } from '../../integrations.styles';
import { useJiraProjects, useJiraIssues, useJiraAssignees } from './useJiraData';

const JiraIntegrationForm = (props) => {
  const { isExistingSR, isJiraLinked, actionField, parentFieldValue, onParentFieldChange, sr, issueKeyFieldName, onGetJiraIssueKey } = props;
  const { cancel, save } = useTexts();
  const { JIRA_ERRORS, okBtn } = useJiraTexts();
  const { checkAndUpdateSr } = useUpdateSR();
  const { data: lockingDetails } = useTicketLockStatus(sr?.id);

  const {
    jiraProjectField,
    jiraTypeField,
    jiraAssigneeField,
    jiraIssueField,
    jiraParentIssueField
  } = useJiraFields();

  const [jiraError, setJiraError] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [fieldsInvalid, setFieldsInvalid] = useState(true);
  const [selectedFieldValues, setSelectedFieldValues] = useState(initialFieldValues);
  const [fieldOptionsLists, setFieldOptionsLists] = useState(initialFieldOptions);
  const [fieldSearchFilters, setFieldSearchFilters] = useState(initialFieldSearchFilters);

  const filters = useMemo(() => ({
    jiraProject: fieldSearchFilters.jiraProject || initialFieldSearchFilters.jiraProject,
    jiraAssignee: fieldSearchFilters.jiraAssignee || initialFieldSearchFilters.jiraAssignee,
    jiraIssue: fieldSearchFilters.jiraIssue || initialFieldSearchFilters.jiraIssue,
    jiraParentIssue: fieldSearchFilters.jiraParentIssue || initialFieldSearchFilters.jiraParentIssue,
  }), [fieldSearchFilters]);

  const resetFields = useCallback(() => {
    setSelectedFieldValues(initialFieldValues);
    setFieldOptionsLists(prevOptions => ({
      ...initialFieldOptions,
      jiraProject: prevOptions.jiraProject,
    }));
    setFieldSearchFilters(initialFieldSearchFilters);
  }, []);

  const handleJiraError = useCallback((errorType, resetFormOnOK, extraInfo = '') => {
    setJiraError({
      title: JIRA_ERRORS[errorType]?.title,
      message: [JIRA_ERRORS[errorType]?.message, extraInfo].filter(Boolean).join('\n'),
      resetFormOnOK,
    });
  }, [JIRA_ERRORS]);

  const userAccount = useSelector(selectActiveUser);
  const showFormButtons = (!isJiraLinked && isExistingSR);

  const { 
    data: projectOptions,
    error: projectsError
  } = useJiraProjects(filters.jiraProject);
  
  const { 
    data: issueOptions,
    error: issuesError
  } = useJiraIssues(selectedFieldValues.jiraProject?.key, filters.jiraIssue);
  
  const { 
    data: parentIssueOptions,
    error: parentIssuesError
  } = useJiraIssues(selectedFieldValues.jiraProject?.key, filters.jiraParentIssue);
  
  const { 
    data: assigneeOptions,
    error: assigneesError
  } = useJiraAssignees(selectedFieldValues.jiraProject?.key, filters.jiraAssignee);

  useEffect(() => {
    const error = projectsError || issuesError || parentIssuesError || assigneesError;
    if (error) {
      handleJiraError(JIRA_ERROR_TYPES.FETCH_JIRA_DATA, false);
      console.error('Error fetching Jira data:', error);
    }
  }, [projectsError, issuesError, parentIssuesError, assigneesError, handleJiraError]);

  useEffect(() => {
    resetFields();
  }, [resetFields]);

  useEffect(() => {
    switch (Number(parentFieldValue)) {
      case jiraActionFieldValues.CREATE.id:
        setFormFields([jiraProjectField, jiraTypeField, jiraParentIssueField, jiraAssigneeField]);
        break;
      case jiraActionFieldValues.LINK.id:
        setFormFields([jiraProjectField, jiraIssueField]);
        break;
      default:
        setFormFields([]);
        return;
    }
    setFieldOptionsLists({
      jiraProject: projectOptions,
      jiraType: [],
      jiraParentIssue: [],
      jiraAssignee: [],
      jiraIssue: [],
    });
  }, [
    parentFieldValue,
    projectOptions,
    jiraProjectField,
    jiraTypeField,
    jiraParentIssueField,
    jiraAssigneeField,
    jiraIssueField,
  ]);

  const setRequiredField = useCallback((fieldTypeName, value) => {
    // if changing jira type to subtask -> change parent issue to required (and vice versa)
    if (fieldTypeName === jiraTypeField.name) {
      setFormFields((prevFields) => prevFields.map((formField) =>
          formField.name === jiraParentIssueField.name ? { ...formField, required: value?.isSubtask } : formField));
    }
  }, [jiraTypeField, jiraParentIssueField]);

  const handleFieldChange = useCallback((fieldType, value) => {
    setSelectedFieldValues((prevValues) => ({
      ...prevValues,
      [fieldType.name]: value,
    }));
    setRequiredField(fieldType.name, value);
  }, [setRequiredField]);

  useEffect(() => {
    const selectedProject = selectedFieldValues.jiraProject;
    if (!selectedProject) {
      return;
    }
    switch (Number(parentFieldValue)) {
      case jiraActionFieldValues.CREATE.id: {
        handleFieldChange(jiraParentIssueField, null);
        handleFieldChange(jiraTypeField, null);
        handleFieldChange(jiraAssigneeField, null);
        
        const types = selectedProject.issueTypes.map((issueType) => ({
          key: Number(issueType.id),
          label: issueType.name,
          isSubtask: issueType.subtask,
        }));
        
        setFieldOptionsLists(prevOptions => ({
          ...prevOptions,
          jiraParentIssue: parentIssueOptions,
          jiraAssignee: assigneeOptions,
          jiraType: types,
          jiraIssue: [],
        }));
        break;
      }
      case jiraActionFieldValues.LINK.id: {
        handleFieldChange(jiraIssueField, null);
        setFieldOptionsLists(prevOptions => ({
          ...prevOptions,
          jiraParentIssue: [],
          jiraAssignee: [],
          jiraType: [],
          jiraIssue: issueOptions,
        }));
        break;
      }
      default:
        setFormFields([]);
        break;
    }
  }, [
    parentFieldValue,
    selectedFieldValues.jiraProject,
    handleFieldChange,
    handleJiraError,
    resetFields,
    jiraProjectField,
    jiraTypeField,
    jiraParentIssueField,
    jiraAssigneeField,
    jiraIssueField,
    issueOptions,
    parentIssueOptions,
    assigneeOptions,
  ]);

  useEffect(() => {
    setFieldsInvalid(
      formFields.some(field => field.required && !selectedFieldValues[field.name]) ||
      selectedFieldValues.jiraType?.isSubtask && !selectedFieldValues.jiraParentIssue
    );
  }, [selectedFieldValues, formFields]);

  const handleCancel = async () => {
    if (sr.id) {
      await lockSR({ username: userAccount?.username, srId: sr.id });
    }
    await checkAndUpdateSr({
      requestParams: { [actionField.fieldName]: null },
      id: sr.id,
      queueUpdate: true,
    });
  };

  const createJiraIssue = useCallback(async () => {
    const reqData = sr.id
      ? {
        srId: sr.id,
        projectKey: selectedFieldValues.jiraProject.key,
        issueTypeName: selectedFieldValues.jiraType?.label,
        parentKey: selectedFieldValues.jiraParentIssue?.key,
        assigneeId: selectedFieldValues.jiraAssignee?.key,
      } : {
        projectKey: selectedFieldValues.jiraProject?.key,
        issueTypeName: selectedFieldValues.jiraType?.label,
        parentKey: selectedFieldValues.jiraParentIssue?.key,
        assigneeId: selectedFieldValues.jiraAssignee?.key,
        srProperties: {
          ...sr,
          requestUser: sr.requestUser?.userName,
        }
      }
    try {
      return await createIssue(reqData);
    } catch (error) {
      const extraInfo = isEmpty(error.response.data.jiraErrors) ? error.response.data.message : error.response.data.jiraErrors.join('\n');
      handleJiraError(JIRA_ERROR_TYPES.CREATE_JIRA_ISSUE, true, extraInfo);
      // TODO proper error handling
      console.log('Error creating jira issue:', error);
      return null;
    }
  }, [
    selectedFieldValues,
    sr,
    handleJiraError,
  ]);

  const getJiraIssueKey = useCallback(() => {
    if (fieldsInvalid) {
      const extraInfo = formFields.filter(field => field.required && !selectedFieldValues[field.name]).map(field => field.displayName).join(', ');
      handleJiraError(JIRA_ERROR_TYPES.FIELDS_INVALID, false, extraInfo);
      return null;
    }
    return (Number(parentFieldValue) === jiraActionFieldValues.CREATE.id) 
      ? createJiraIssue() 
      : selectedFieldValues.jiraIssue?.key
  }, [parentFieldValue, createJiraIssue, fieldsInvalid, formFields, selectedFieldValues, handleJiraError]);

  const handleSave = async () => {
    const issueKey = await getJiraIssueKey();
    if (sr.id) {
      await lockSR({ username: userAccount?.username, srId: sr.id });
    }
    await checkAndUpdateSr({
      requestParams: { [issueKeyFieldName]: issueKey },
      id: sr.id,
      queueUpdate: true,
    });
  };

  const handleClosePrompt = () => {
    if (jiraError?.resetFormOnOK) {
      handleCancel();
    }
    setJiraError(null);
  }

  useEffect(() => {
    if (onGetJiraIssueKey) {
      onGetJiraIssueKey(getJiraIssueKey);
    }
  }, [onGetJiraIssueKey, getJiraIssueKey]);

  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;

  const handleFieldSearch = useCallback((field, searchString) => {
    const value = searchString || undefined;
    setFieldSearchFilters(prev => ({
      ...prev,
      [field.name]: value
    }));
  }, []);

  return (
    <>
      <TemplateFieldsGrid
        {...props}
        isTicketLocked={isTicketLocked}
        fields={[actionField]}
        onFieldChange={onParentFieldChange}
        isJiraIntegrationSection
      />
      {!isEmpty(formFields) && (
        <FieldGroupWrapper key={actionField.id}>
          <Grid container spacing={2} justifyContent="space-between">
            {formFields.map((field) => (
              <FieldsRowWrapper halfRow key={field.name} id={`grid_item_${field.name}`}>
                <Box width="100%" data-ispropertiesfield="true">
                  <StyledIntegrationFieldContainer
                    className="field-container"
                    id={field.name}
                    data-testid={`field_${field.name}`}
                  >
                    <IntegrationFieldComponent
                      className="field-wrapper"
                      field={field}
                      onChange={(value) => handleFieldChange(field, value)}
                      onSearch={(value) => handleFieldSearch(field, value)}
                      options={fieldOptionsLists[field.name]}
                    />
                  </StyledIntegrationFieldContainer>
                </Box>
              </FieldsRowWrapper>
            ))}
          </Grid>
          {showFormButtons && (
            <StyledFormFooter>
              <button className="cancel-btn" onClick={handleCancel} data-testid="jira-form-cancel-btn">
                {cancel}
              </button>
              <button className="ok-btn" onClick={handleSave} disabled={fieldsInvalid} data-testid="jira-form-save-btn">
                {save}
              </button>
            </StyledFormFooter>
          )}
        </FieldGroupWrapper>
      )}
      {jiraError && (
        <MessagePrompt
          open
          onClose={handleClosePrompt}
          onOkClick={handleClosePrompt}
          showCancelBtn={false}
          title={jiraError.title}
          btnOkText={okBtn}
          Icon={ErrorSign}
          data-testid="jira-error-prompt"
        >
          {jiraError.message}
        </MessagePrompt>
      )}
    </>
  );
};

export default JiraIntegrationForm;
