import { useRef, useMemo, useCallback, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import { isEmpty } from 'common/utils/utils';
import CustomScroll from 'common/components/customScroll';
import DropDownHeader from 'common/components/dropdownSelect/dropDownHeader';
import { InfoTypography } from 'common/components/dropdownSelect/InfoTypography';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import { StyledDropdownItem } from 'features/resolutionPanel/middlePanel/relatedItems/linkItemProcess/style';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { useIntegrationDropdown } from '../hooks/useIntegrationDropdown';
import { useIntegrationSearch } from '../hooks/useIntegrationSearch';
import { customScrollProps } from './IntegrationFieldComponent.consts';
import { JIRA_API_PAGE_SIZE } from './jira/jira.consts';
import { StyledDropdown, StyledIntegrationTextField, StyledDropDownMenu, StyledInputContainer } from '../integrations.styles';
import useTexts from '../useTexts';

const IntegrationSelectField = ({ field, options, onChange, onSearch }) => {
  const ref = useRef();
  const { searchPlaceholder, clear, placeholder, emptyResults, notAvailable } = useTexts();
  
  const {
    dropdownMenuOpen,
    value,
    internalFilter,
    filteredOptions,
    isEnabled,
    setInternalFilter,
    setFilteredOptions,
    filterOptions,
    handleOpenMenu,
    handleCloseMenu,
    handleSelect,
    actionsProps,
  } = useIntegrationDropdown(options, field, onChange);
  
  const {
    isApiSearchMode,
    onSearchChange,
    onSearchClear,
    debouncedSearch
  } = useIntegrationSearch(field.name, options, onSearch);
  
  useEffect(() => {
    const currentLength = options?.length ?? 0;
    isApiSearchMode.current = currentLength === 0 || currentLength === JIRA_API_PAGE_SIZE;
    
    const filtered = filterOptions(options, internalFilter);
    setFilteredOptions(filtered);
  }, [options, internalFilter, filterOptions, setFilteredOptions, isApiSearchMode]);
  
  const handleSearchChange = useCallback((e) => {
    const newValue = onSearchChange(e);
    setInternalFilter(newValue);
  }, [onSearchChange, setInternalFilter]);
  
  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const searchFieldProps = useMemo(() => ({
    value: internalFilter,
    placeholderText: searchPlaceholder,
    onChange: handleSearchChange,
    onClear: () => {
      setInternalFilter('');
      onSearchClear();
    },
    inputProps: {
      'data-testid': `${field.name}-search-field`,
    },
    autoFocus: true,
  }), [field.name, internalFilter, searchPlaceholder, handleSearchChange, onSearchClear, setInternalFilter]);

  return (
    <StyledDropdown>
      <FieldName required={field.required} name={field.displayName} />
      <StyledInputContainer 
        isEnabled={isEnabled}
        isOpen={dropdownMenuOpen}
        hasValue={!!value}
      >
        <StyledIntegrationTextField
          id={field.name}
          data-testid={`dropdown_${field.name}`}
          placeholder={isEnabled ? placeholder : notAvailable}
          value={value?.label ?? ''}
          onClick={handleOpenMenu}
          autoComplete="off"
          type="text"
          ref={ref}
          isOpen={dropdownMenuOpen}
          hasValue={value}
          isEnabled={isEnabled}
        />
        {isEnabled ? (
          <ArrowDownIcon data-testid="arrow-down-icon" />
        ) : (
          <></>
        )}
      </StyledInputContainer>
      <StyledDropDownMenu
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={dropdownMenuOpen}
        onClose={handleCloseMenu}
        containerWidth={ref?.current?.offsetWidth ?? 224}
      >
        <CustomScroll {...customScrollProps}>
          {isEnabled && (
            <DropDownHeader
              isSearchVisible
              searchFieldProps={searchFieldProps}
              isActionsVisible
              actionsProps={{
                ...actionsProps,
                clearText: clear,
              }}
              isDividerVisible
            />
          )}
          {isEmpty(filteredOptions) ? (
            <InfoTypography message={emptyResults} />
          ) : (
            filteredOptions?.map((option) => (
              <MenuItem
                key={option.key}
                onClick={() => handleSelect(option)}
              >
                <StyledDropdownItem>{option.label}</StyledDropdownItem>
              </MenuItem>
            )))
          }
        </CustomScroll>
      </StyledDropDownMenu>
    </StyledDropdown>
  );
};

export const IntegrationFieldComponent = ({ field, options, onChange, onSearch }) => {
  switch (field.type) {
    case 'select':
      return <IntegrationSelectField 
        field={field} 
        options={options} 
        onChange={onChange}
        onSearch={onSearch}
      />
    default:
      break;
  }
};
